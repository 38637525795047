import '../../css/main.css';

import PolandMap from '../../js/app/map/poland-map';
window.PolandMap = PolandMap;

import Main from '../../js/app/main/main.js';
window.Main = Main;

$(document).ready(function() {
	window.polandMap = new PolandMap();
	window.controller = new Main();	
});
