export default class PolandMap {
    constructor() {
        $('[id^="lv"]').each(function () {
            let id = $(this).attr('id');
            let num = id.substr(id.indexOf("lv") + 2);
            $("#av" + num)
                .mouseover(function () {
                    $(".v" + num).velocity("stop").velocity('fadeIn', {duration: 200});
                })
                .mouseout(function () {
                    $(".v" + num).velocity("stop").velocity('fadeOut', {duration: 200});
                });
        });
    }
}


