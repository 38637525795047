export default class Main {
	
	constructor() {
		$.fn.selectpicker.Constructor.DEFAULTS.size = 5;	
        $('#currency-select').selectpicker().css('display', 'block');
        $('#ecantor-currency-select').selectpicker().css('display', 'block');
        $('#bank-select').selectpicker().css('display', 'block');
        
        this._initOnSubmitAction();
	}

	currencyChanged() {
    	var symbol =  $('#currency-select').val();

        window.location = PATH + '/waluta/' + symbol +'.html'
    }
	
	_initOnSubmitAction() {
		$('#form > form').submit(function() {
			var currency = $('#ecantor-currency-select').val();
			$('#hcurrency').val(currency);
			
			var bank = $('#bank-select').val();
			$('#hbank').val(bank);
			
		    return true;
		});
	}
	
	
}